<ng-template class="theme-modal" #itemGallery let-modal>
  <div class="modal-content item-gallery-modal">
    <div class="modal-body">
      <button type="button" class="close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row">
        <div class="col-10 ">
          <div class="item-gallery-img">
            <owl-carousel-o [options]="ItemGallerySliderConfig" #owlCar class="home-slider">
              <ng-container *ngFor="let image of images; index as i">
                <ng-template carouselSlide [id]="i">
                  <div class="img-wrapper">
                    <lib-ngx-image-zoom [thumbImage]=image.modal.img [fullImage]=image.modal.img zoomMode="click"
                      maxZoomRatio="10" [scrollStepSize]=".1" magnification=".5"
                      enableScrollZoom="true">
                    </lib-ngx-image-zoom>
                    <!-- html -->
                    <!-- <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="image.modal.img" [alt]="image.alt" class="img-fluid cursor"> -->
                    <!-- <ng-magnizoom imageClass="img-fluid" imageSrc="{{image.modal.img}}"></ng-magnizoom> -->
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>


        </div>
        <div class="col-2 m-a col-sm-d-none">
          <section class="portfolio-section pt-0 port-col zoom-gallery">
            <div class="container">
              <div class="row isotopeContainer">
                <div class="col-sm-12 isotopeSelector" *ngFor="let image of images; index as i">
                  <div class="overlay" [class.active]="i == activeSlide">
                    <div class="border-portfolio">
                      <img [src]="image.modal.img" class="img-fluid" (click)="owlCar.to(activeSlide = i.toString())">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</ng-template>