import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	id?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{ path: '/home', title: 'home-page', type: 'link' },
	];

	LEFTMENUITEMS: Menu[] = [
		{
			path: '/', title: 'Home', type: 'sub', active: false, children: [
			  { path: '/:feature-arts', title: 'Feature Arts', type: 'section', id: 'feature-arts' },
			  { path: '/:latest-item', title: 'Latest Items', type: 'section', id:'latest-item' },
			  { path: '/:explore', title: 'Explore', type: 'section', id:'explore' },
			  { path: '/:popular-item', title: 'Popular Items', type: 'section', id:'popular-item' },
			  { path: '/:event', title: 'Events', type: 'section', id:'event' }
			]
		},
		{
			path: '/pages/aboutus', title: 'About Us', type: 'link'
		},
		{
			path: '/pages/contact', title: 'Contact Us', type: 'link'
		},
		{
			path: '/shop/cart', title: 'View Cart', type: 'link'
		},
		{
			path: '/shop/checkout', title: 'Checkout', type: 'link'
		},
		{
			path: '/shop/wishlist', title: 'Wishlist', type: 'link'
		},
		{
			path: '/shop/collection/left/sidebar', title: 'Explore', type: 'link'
		},
		{
			path: '/pages/dashboard', title: 'Dashboard', type: 'link'
		}
		
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
