<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
	  <ng-template carouselSlide>
	    <div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+sliderBack+')', 'background-repeat': 'repeat', 'background-size': 'initial'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain">
					<div class="slider-main-img collection-banner col col-xl-6 col-lg-12 col-md-12 col-sm-12">
						<!-- <lib-ngx-image-zoom 
							[thumbImage]=slider.image1
							[fullImage]=slider.image1
							[magnification]="1"
							[enableScrollZoom]="true"
							[enableLens]="true"
							[lensWidth]="200"
							[]
						></lib-ngx-image-zoom> -->
						<!-- <a [routerLink]="['/shop/product/left/sidebar/', slider.subTitle.replace(' ', '-')]"> -->
							<img [src]="slider.image1" alt="product-image" class="img-fluid lazyload">
						<!-- </a> -->
						
					</div>
					<div class="col col-xl-6 col-lg-12 col-md-12 col-sm-12">
						<h4>{{slider.title}}</h4>
						<h1>{{slider.subTitle}}</h1>
						<!-- [routerLink]="['/shop/collection/left/sidebar']" 
						[queryParams]="{ category: category}"  -->
						<a [routerLink]="['/shop/product/left/sidebar/', slider.subTitle.replace(' ', '-')]"					
						class="btn btn-solid" [ngClass]="buttonClass">
						{{buttonText}}
						</a>
					</div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o>