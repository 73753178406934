import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MyEvent } from 'src/app/shared/tables/interface/my-event';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public modalOpen: boolean = false;
  public event: MyEvent[] = [];
  url = environment.imgURL;
  @ViewChild("eventDetailView", { static: false }) EventDetailView: TemplateRef<any>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  openModal(event) {
    this.event.pop();
    this.event.push(event);
    // console.log('event', this.event);
    // console.log('event', this.event[0]?.id);
    
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.EventDetailView, { 
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'event-detail' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
     
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
