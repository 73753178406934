<app-header-one [sticky]="true" [themeLogo]="themeLogo"></app-header-one>
<ngx-spinner type="ball-scale-multiple" id="loading"></ngx-spinner>
<!-- Home slider start-->
<section class="p-0" id="feature-arts" *ngIf="featuredItems?.length>0">
  <app-slider [sliders]="featuredItems" [sliderBack]="sliderBack" [textClass]="'text-center'" [category]="'fashion'" [buttonText]="'Explore Art'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- latest items slider start-->
<div class="title1 section-t-space" id="latest-item">
  <h4>Available For Sale</h4>
  <h2 class="title-inner1">Latest Items</h2>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="product-para">
        <p class="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry,
          Lorem Ipsum has been the industry's standard.
        </p>
      </div>
    </div>
  </div>
</div>
 
<section class="section-b-space p-t-0" *ngIf="productsLatest?.length>0">
  <div class="container">
    <div class="row">
      <div class="col app-collection-sect">
        <owl-carousel-o class="category-m home-slider" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of productsLatest | slice:0:6">
            <ng-template carouselSlide>
              <div class="product-box latest-item">
                <app-product-box-latest [product]="product" [currency]="productService?.Currency" [thumbnail]="true"
                  [cartModal]="true">
                </app-product-box-latest>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- latest items slider End -->
<!-- Explore -->
<div class="title1">
  <h4>Schools</h4>
  <h2 class="title-inner1">Explore</h2>
</div>

<!-- collection category -->
<section class="p-0" id="explore" *ngIf="categories?.length>0">
  <div class="full-banner parallax-banner1 parallax text-center p-left"
    [ngStyle]="{'background-image': 'url('+catBackgroundImg+')'}">
    <div class="container-fluid">
      <div class="row partition3">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 m-b-3" *ngFor="let cat of categories">
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ school: cat.cat_name}">
            <!-- [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'furniture'}" -->
            <div class="collection-banner p-right text-right">
              <img src="{{url}}{{cat?.cat_image}}" alt="collection" class="img-fluid lazyload">
              <div class="contain-banner banner-3">
                <div>
                  <h4>+{{cat.ItemCategories?.length}} Items</h4>
                  <h2>{{cat.cat_name}}</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <section class="p-0" *ngIf="materials?.length>0">
        <div class="row partition1 p-2">
            <div class="p-2 mb-2" *ngFor="let mat of materials">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ medium: mat.mat_name}" class="btn btn-outline btn-block">
                {{mat.mat_name}}
                </a>
            </div>
        </div>
      </section>
    </div>

  </div>
</section>
<!-- collection Category end -->

<!-- Tab product start-->
<div class="title1 section-t-space">
  <h4>exclusive arts</h4>
  <h2 class="title-inner1">Popular Items</h2>
</div>
<section class="section-b-space p-t-0" id="popular-item" *ngIf="productCollections?.length>0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
          <ngb-tab *ngFor="let collection of productCollections">
            <ng-template ngbTabTitle>{{collection}}</ng-template>
            <ng-template ngbTabContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:16">
                  <div class="product-box">
                    <app-product-box-one [product]="product" [currency]="productService?.Currency" [thumbnail]="true"
                      [cartModal]="true">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->






<!-- Event section start-->
<section class="blog p-t-0" id="event">
  <div class="container border-section p-t-6">
    <div class="row">
      <div class="col">
        <div class="title1 title5 ">
          <!-- <h4>Upcoming</h4> -->
          <h2 class="title-inner1">{{eventsLabel}}</h2>
          <hr role="tournament6">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="events?.length>0">
      <div class="col-md-12">
        <app-blog [events]="events" (childEvent)="receiveDataFromChild($event)"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- services start-->
<div class="container">
  <section class="service small-section">
    <app-services></app-services>
  </section>
</div>
<!-- services End -->
<!-- <app-newsletter [hidden]='true'></app-newsletter> -->
<app-footer-three [themeLogo]="themeLogo" [subFooter]="true"></app-footer-three>