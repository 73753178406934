import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from 'src/app/shared/services/main.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FAQ } from 'src/app/shared/tables/interface/page';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  public faqs: FAQ[] = [];
  public closeResult: string;
  public modalOpen: boolean = false;
  @ViewChild("faqView", { static: false }) FaqView: TemplateRef<any>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal, private mainService: MainService, private spinner: NgxSpinnerService) { }
  getFaq():void{
    this.spinner.show();
    this.mainService.getFaq().subscribe(
      res => {
        res.forEach((faq, index) => {
          this.faqs.push({
            id: faq.id,
            serial: index+1,
            question: faq.question,
            answer: faq.answer
          })
        });
        this.spinner.hide();
      },
      error => {
        console.log(error);
        this.spinner.hide();
      }
    )
  }
  ngOnInit(): void {
    this.getFaq();
  }
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.FaqView, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
     
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
