import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   
  constructor(public authService: AuthService, public router: Router){

  }
  canActivate():boolean{
    if(this.authService.loggedIn()){
      return true
    }else{
      this.router.navigate(['/pages/login'])
      return false
    }
  }
  
}
