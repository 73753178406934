import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public category: any[]=[];
  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
  }

  get filterbyCategory() {
    // this.category = [...new Set()]
    this.products.filter(item => item.ItemCategories.filter(cat=>{ const index = this.category.indexOf(cat.Category['cat_name']);
    if (index === -1) this.category.push(cat.Category['cat_name'])}))
    return this.category
  }

}
