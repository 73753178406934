import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { Item } from '../tables/interface/item';
import { DatePipe } from '@angular/common';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Tax } from '../classes/tax';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.apiURL+'/fronts';
  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products;
  public PendingProducts;
  public Taxes;

  constructor(public router: Router, public authService: AuthService, private http: HttpClient, private toastrService: ToastrService,  private tokenStorage: TokenStorageService) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  // private get products(): Observable<Product[]> {
  //   this.Products = this.http.get<Product[]>(`${this.baseUrl}/items/`).pipe(map(data => data));
  //   let itemsData: Item[] = [];
  //   this.Products.subscribe(next => {
  //     next.forEach(row => {
  //       row.Items.forEach(item => {
  //         item['vendor']=row.brandName;
  //         itemsData.push(item);
  //         localStorage['products'] = JSON.stringify(item);
  //       });

  //     });
  //   });

  //   console.log('items data:', itemsData);
  //   console.log("this.Products", this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]'))));

  //   // this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
  //   // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
  //   return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  //   // return JSON.parse(localStorage['products'] || '[]');
  // }
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>(`${this.baseUrl}/items/`).pipe(map(data => data));
    let itemsData: Item[] = [];
    this.Products.subscribe(next => {
      itemsData.push(next);
      localStorage['products'] = JSON.stringify(next);
    });
    
    // console.log('items data:', itemsData);
    // console.log("this.Products", this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]'))));
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // get pending products
  private get pendingProducts(): Observable<Product[]> {
    const userId = this.tokenStorage.getUser().userId;
    this.PendingProducts = this.http.get<Product[]>(`${this.baseUrl}/items/pending/${userId}`).pipe(map(data => data));
    let itemsData: Item[] = [];
    this.PendingProducts.subscribe(next => {
      itemsData.push(next);
      localStorage['pending-products'] = JSON.stringify(next);
    });
    
    // console.log('items data:', itemsData);
    // console.log("this.PendingProducts", this.PendingProducts.pipe(startWith(JSON.parse(localStorage['products'] || '[]'))));
    return this.PendingProducts = this.PendingProducts.pipe(startWith(JSON.parse(localStorage['pending-products'] || '[]')));
  }

  // Get PendingProducts
  public get getPendingProducts(): Observable<Product[]> {
    return this.pendingProducts;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.title.replace(' ', '-') === slug;
      });
    }));
  }

  


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    // console.log("AFTER ADDING: ", JSON.stringify(state.wishlist));
    return true
  }

  // Get Wishlist Item By Id
  public isInWishlist(id): Boolean {
    // console.log("STAET BEFORE CHECK: ", state.wishlist)
    const wishlistItem = state.wishlist.find(item => item.id == id)
    // console.log("ITEM AFTER CHECK: ", wishlistItem)
    if (wishlistItem) {
      return true;
    }
    return false;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    console.log("STATE.WISHLIST: ", state.wishlist)
    this.toastrService.success('Product has been removed from wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }
  public emptyCart(): any {
    state.cart = JSON.parse('[]')
    window.localStorage.removeItem('cartItems');
    return true;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if(!stock) return false

    // if(!this.authService.loggedIn()){
    //   this.router.navigate(['/pages/login'])
    //   return false
    // }

    if (cartItem) {
        // cartItem.quantity += qty
        return false
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    // console.log("this.cartItems: ", this.cartItems)
    // console.log("CART ITEMS: ", state.cart.length)
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        // console.log("PREV: ", prev)
        // console.log("CURR: ", curr)
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        // return (prev + price * curr.stock) * this.Currency.price;
        return (prev + price * curr["quantity"]) * this.Currency.price;
      }, 0);
    }));
  }
  public cartTotalAmountTax(tax: number): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      const subtotal = product.reduce((prev, curr: Product) => {
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return prev + (price * curr["quantity"]);
      }, 0);
    
      const taxRate = tax/100; // assume tax rate is 10%
      const taxValue = subtotal * taxRate;
      const total = subtotal + taxValue;
    
      return total * this.Currency.price;
    }));
     
  }
  public cartTaxAmount(tax: number): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      const subtotal = product.reduce((prev, curr: Product) => {
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return prev + (price * curr["quantity"]);
      }, 0);
    
      const taxRate = tax/100; // assume tax rate is 10%
      const taxValue = subtotal * taxRate;
      // const total = subtotal + taxValue;
    
      return taxValue * this.Currency.price;
    }));
     
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true;
        // console.log('filter varaible ', filter);
        const Tags = filter.some((prev) => { // Match Tags
          const check_cat =  item.ItemCategories.filter(cat=>{ 
            if (filter.includes(cat.Category['cat_name'])) return true;
          })
          const check_mat =  item.ItemMaterials.filter(mat=>{ 
            if (filter.includes(mat.Material['mat_name'])) return true;
          })
          if(check_cat?.length>0 || check_mat?.length>0){
            // console.log('check_cat length', check_cat?.length);
            // console.log('check_mat length', check_mat?.length);
            return prev
          } 

        })
        // console.log('Tags ', Tags);
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  updateViews(productId: number): Observable<any> {
    return this.http.put(`${this.baseUrl}/items/${productId}/views`, {});
  }

  
}
