<owl-carousel-o [options]="BlogSliderConfig" class="home-slider blog-slider"  (change)="getPassedData($event)">
  <ng-container *ngFor="let event of events">
    <ng-template carouselSlide>
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-5 col-md-12 col-sm-12 m-a">
            <div class="classic-effect" style="max-height: 270px;">
              <img [lazyLoad]="url+event.coverImage" [src]="url+event.coverImage" class="img-fluid" alt="event">
              <span></span>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="blog-details">
              <table>
                <tr>
                  <td>
                    <h4><i class="fa fa-map-marker"></i></h4>
                  </td>
                  <td>
                    <h4 style="padding-left: 5px;">{{' ' + event.location}}</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4><i class="fa fa-calendar"></i></h4>
                  </td>
                  <td>
                    <h4 style="padding-left: 5px;">{{' ' + event.startTime|date: 'MMM d, y, h:mm:ss a'}} - {{event.endTime|date: 'MMM d, y, h:mm:ss a'}}</h4>
                  </td>
                </tr>
              </table>

              <p class="title">
                <!-- [routerLink]="'/pages/event/details'" -->
                <a href="javascript:void(0)" (click)="EventDetailView.openModal(event)">{{event.subject}}
                </a>
              </p>
              <hr class="style1">
              <div class="border-product">
                <p class="p-desc">{{ event.description.substring(0, 200)+'...' }}</p>
              </div>
              <div class="border-product">
                <div class="row">
                  <div class="col-lg-7">
                    <h6>{{'Organized by: '+event.organizer}}</h6>
                    <h6 style="color: var(--theme-deafult);"><i class="fa fa-info-circle"></i> {{event.eventType}}</h6>
                  </div>
                  <div class="col-lg-5 blog-btn">
                    <div class="product-buttons">
                      <button (click)="EventDetailView.openModal(event)" class="btn btn-solid">Read more</button> 
                      <a href="{{event.regURL}}" target="_blank" rel="noopener noreferrer" class="ml-3 f18"><i class="fa fa-user-plus" aria-hidden="true"></i></a>
                      <!-- <a href="{{event.regURL}}" target="_blank" rel="noopener noreferrer"></a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
<app-event-detail #eventDetailView ></app-event-detail>