<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="SearchView.openModal()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
    </li>
    <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i>
      </div>
      <div class="show-div setting">
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
              <img class="mr-3" [src]="url+product?.Images[0].src" [alt]="product?.Images[0].alt">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x
                  {{ product?.price * productService?.Currency.price | discount:product |
                  currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
    <li class="onhover-div mobile-account login-btn" *ngIf='!loggedIn'  [routerLink]="['/pages/login']" >
      <div class="btn btn-outline"><i class="fa fa-user" aria-hidden="true"></i>Login</div>
      <!-- <div class="show-div setting">
        <h6>My Account</h6>
        <ul>
          <li>
            <a href="javascript:void(0)" data-lng="en">
              Login
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-lng="es">
              Logout
            </a>
          </li>
        </ul>
      </div> -->
    </li>
    <li class="onhover-div mobile-account login-btn" *ngIf='loggedIn'  [routerLink]="['/pages/dashboard']" >
      <div class="btn btn-outline"><i class="fa fa-user" aria-hidden="true"></i> {{user.lname}}</div>
    </li>
    <li>
      <app-left-menu></app-left-menu>
    </li>
  </ul>
</div>
<app-search-view #searchView></app-search-view>