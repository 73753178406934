<ng-template class="theme-modal" #eventDetailView let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Event Detail </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-md-12">
            <div class="row">
              <div class="col-lg-5 m-a">
                <div class="classic-effect" style="max-height: 270px;">
                  <img [lazyLoad]="url+event[0]?.coverImage" [src]="url+event[0]?.coverImage" class="img-fluid" alt="event">
                  <span></span>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="blog-details">
                  <table>
                    <tr>
                      <td>
                        <h4><i class="fa fa-map-marker"></i></h4>
                      </td>
                      <td>
                        <h4 style="padding-left: 5px;">{{' ' + event[0]?.location}}</h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4><i class="fa fa-calendar"></i></h4>
                      </td>
                      <td>
                        <h4 style="padding-left: 5px;">{{' ' + event[0]?.startTime|date: 'MMM d, y, h:mm:ss a'}} - {{event[0]?.endTime|date: 'MMM d, y, h:mm:ss a'}}</h4>
                      </td>
                    </tr>
                  </table>
    
                  <p class="title">
                    <!-- [routerLink]="'/pages/event/details'" -->
                    <a href="#">{{event[0]?.subject}}
                    </a>
                  </p>
                  <hr class="style1">
                  <div class="border-product">
                    <p class="p-desc">{{ event[0]?.description}}</p>
                  </div>
                  <div class="border-product">
                    <div class="row">
                      <div class="col-lg-7">
                        <h6>{{'Organized by: '+event[0]?.organizer}}</h6>
                        <h6 style="color: var(--theme-deafult);"><i class="fa fa-info-circle"></i> {{event[0]?.eventType}}</h6>
                      </div>
                      <div class="col-lg-5 blog-btn">
                        <div class="product-buttons">
                          <a href="{{event[0]?.regURL}}" target="_blank" rel="noopener noreferrer" class="ml-3 f18"><i class="fa fa-user-plus" aria-hidden="true"></i></a>
                          <!-- <a href="{{event[0]?.regURL}}" target="_blank" rel="noopener noreferrer"></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss( 'Cross click')">Close</button>
    </div>
</ng-template>