import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { EventDetailComponent } from 'src/app/shared/components/modal/event-detail/event-detail.component';
import { BlogSlider } from '../../../shared/data/slider';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  url = environment.imgURL;
  @Input() events: any[] = [];
  @ViewChild("eventDetailView") EventDetailView: EventDetailComponent;

  @Output() childEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    
  }

  public BlogSliderConfig: any = BlogSlider;

  getPassedData(data: any) {
    // console.log('data', data);
    // console.log('data position', data.startPosition);

    const selectedEvent = this.events[data.startPosition];
    // Now you have the selected event, you can perform any action you want
    // console.log('Selected Event:', selectedEvent);
    const startDate = selectedEvent.createdAt;
    const endDate = selectedEvent.endTime;

    const currentDate = new Date();

    if (currentDate >= startDate && currentDate <= endDate) {
      // console.log('The current date is within the event date range.');
      this.childEvent.emit('Upcoming Events');
    } else {
      // console.log('The current date is outside the event date range.');
      this.childEvent.emit('Past Events');
    }
  }
}
