import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public materials: any[]=[];
  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
  }

  get filterbyMaterial() { 
    this.products.filter(item => item.ItemMaterials.filter(cat=>{ const index = this.materials.indexOf(cat.Material['mat_name']);
    if (index === -1) this.materials.push(cat.Material['mat_name'])}))
    return this.materials
  }

}
