import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProductSlider } from '../shared/data/slider';
import { Product } from '../shared/classes/product';
import { ProductService } from '../shared/services/product.service';
import { MyDataService } from "../shared/services/mydata.service";
import { Category } from '../shared/tables/interface/category';
import { Material } from '../shared/tables/interface/material';
import { MainService } from '../shared/services/main.service';
import { Feature } from '../shared/tables/interface/feature';
import { DatePipe } from '@angular/common';
import { MyEvent } from '../shared/tables/interface/my-event';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../shared/services/token-storage.service';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePage implements OnInit {
  url = environment.imgURL;
  themeLogo: string; // Change Logo
  catBackgroundImg: string; // Change Logo
  sliderBack: string; // Change Logo
  public eventsLabel: string = 'Events';
  public events: MyEvent[] = [];
  public categories: Category[] = [];
  public featuredItems: Feature[] = [];
  public materials: Material[] = [];
  public products: Product[] = [];
  public productsLatest: Product[] = [];
  public productCollections: any[] = [];
  public userId : number;


  constructor(public productService: ProductService, private data: MyDataService, private mainService: MainService, private datePipe: DatePipe, private spinner: NgxSpinnerService, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private tokenStorageService: TokenStorageService) {
    this.spinner.show();
    this.productService.getProducts.subscribe(response => {
      // console.log('response value', response);
      this.products = response;
      this.productsLatest = response.filter(item => {
        if (item.collection[0] == 'new products' && !item?.Arders?.length && this.userId!=item?.userId) {
          return item;
        }
      });
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
      // console.log('productCollections ', this.productCollections)
    });
    this.getFeaturedItems();
    this.getItemAtrributes();
    this.getEvents();
    this.spinner.hide();
    // console.log('events', this.events);
  }

  getEvents(): void {
    this.mainService.getEvents().subscribe(
      res => {
        // console.log(res);
        res.forEach(element => {
          this.events.push(element);
        });
      },
      error => {
        console.log(error);
      }
    )
  }
  getItemAtrributes(): void {
    this.mainService.getAttribute().subscribe(
      res => {
        // console.log(res.categories);
        this.categories = res.categories;
        this.materials = res.materials;
      },
      error => {
        console.log(error);
      }
    )
  }
  getFeaturedItems(): void {
    this.mainService.getFeaturedItems().subscribe(
      res => {
        // console.log(res);
        res.forEach(user => {
          user.Items.forEach(item => {
            this.featuredItems.push({
              title: user.brandName,
              subTitle: item.title,
              // link: item.subTitle.replace(/\s+/g, '-'),
              link: item.subTitle,
              image1: `${this.url}/items/${item?.Images[0]?.src}`,
            })
          });
        });
        // this.featuredItems=res;
      },
      error => {
        console.log(error);
      }
    )
  }

  public ProductSliderConfig: any = ProductSlider;


  ngOnInit(): void {
    this.userId = this.tokenStorageService.getUser().userId;
    this.data.currentThemeLogo.subscribe(themelogo => this.themeLogo = themelogo);
    this.data.currentCatBackground.subscribe(catBack => this.catBackgroundImg = catBack);
    this.data.currentSliderBackground.subscribe(sliderBack => this.sliderBack = sliderBack);

    this.route.params.subscribe(params => {
      const sectionId = params.section;
      if (sectionId) {
        // Scroll to the section with the given ID
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    // console.log('collection value', collection);
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  receiveDataFromChild(data: string) {
    this.eventsLabel = data;
    // console.log('receiveDataFromChild', data);
    // Manually trigger change detection
    this.cdr.detectChanges();
  }

  
}
