<div *ngIf="!loader">
  <div>
    <div class="category-wrapper category-wrapper-1">
      <div>
        <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
          <div class="product-box">
            <img
                  [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="ImageSrc ? ImageSrc : url+product.Images[0].src"
                  class="img-fluid category-img-h lazy-loading"
                  alt="{{ product.Images[0].alt }}" />
          </div>
        </a>

          <h5><a [routerLink]="['/pages/vendor/vendor-profile/', product?.User?.slug]">{{ product?.User?.brandName }}</a></h5>

          <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]"><h4>{{ product?.title | titlecase}}</h4></a>
          <ul class="category-link">

            <li *ngFor="let cat of product?.ItemCategories"><a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: cat?.Category?.cat_name}">{{cat?.Category?.cat_name}}</a></li>
            <li > <span *ngFor="let mat of product?.ItemMaterials; let i = index" [attr.data-index]="i" > <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ medium: mat?.Material?.mat_name}">{{mat?.Material?.mat_name}}</a>{{product?.ItemMaterials.length==(i+1)?'':', '}}</span></li>
            <!-- <li><a href="#">damaskeening</a></li><li><a href="#">diving watch</a></li> -->
          </ul>
          <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]"
              class="btn btn-outline">
              view more
          </a> -->
          <div class="cart-info2 cart-wrap2">
            <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
              *ngIf="cartModal">
              <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
              *ngIf="!cartModal">
              <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to Wishlist" (click)="isInWishlist(product.id) ? removeFromWishlist(product) : addToWishlist(product)">
              <i [class]="isInWishlist(product.id) ? 'text-danger fa fa-heart' : 'ti-heart'" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
              <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Share To" (click)="ShareView.openModal()">
              <i class="ti-share" aria-hidden="true"></i>
            </a>
            <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
              <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
          </div>
      </div>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-share-modal #shareView [product]="product" ></app-share-modal>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

