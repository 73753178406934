<!-- footer -->
<footer [ngClass]="class" class="d-print-none">
    <div class="white-layout" *ngIf="mainFooter">
        <section class="p-0">
            <div class="container-fluid">
                <div class="row footer-theme2 section-light footer-border">
                    <div class="col">
                        <li class="faq"><a href="javascript:void(0)" (click)="FaqView.openModal()"><i aria-hidden="true" class="fa fa-question-circle-o"></i></a></li>
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>
                                <div class="footer-contant">
                                    <div class="footer-logo">
                                        <img [src]="themeLogo" alt="">
                                    </div>
                                    <div class="social-white">
                                        <ul>
                                            <li *ngFor="let social of socials; let i=index">
                                                <a href="javascript:void(0)"><i [class]="social.icon" aria-hidden="true"></i></a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-p p-0">
                        <div class="footer-block">
                            <div class="subscribe-white">
                                <h2>newsletter</h2>
                                <form class="auth-form needs-validation" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="mce-EMAIL" placeholder="Enter your email" [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" name="email" [formControl]="emailForm.controls['email']" required>
                                        <button type="submit" id="mc-submit" [disabled]="!emailForm.valid" class="btn btn-solid black-btn">subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title">
                                    <h4>store information</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul class="contact-details">
                                        <li><a href="https://g.page/itolotech" target="_blank" rel="noopener" [innerHtml]="contactInfo&&contactInfo.address"></a></li>
                                        <li>Call Us: {{ contactInfo&&contactInfo.phone1 }}</li>
                                        <li>Email Us: <a href="mailto:{{ contactInfo&&contactInfo.email1 }}">{{ contactInfo&&contactInfo.email1 }}</a></li>
                                        <li>Fax: {{ contactInfo&&contactInfo.fax1 }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="white-layout box-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li><a [routerLink]="['/pages/aboutus']">about us</a></li>
                                    <li><a [routerLink]="['/pages/contact']">contact us</a></li>
                                    <li><a [routerLink]="['/shop/collection/left/sidebar']">explore</a></li>
                                    <li><a [routerLink]="['/shop/cart']">cart</a></li>
                                    <li><a [routerLink]="['/pages/dashboard']">dashboard</a></li>
                                    <li><a [routerLink]="['/pages/faq']">FAQ</a></li>
                                    <li><a [routerLink]="['/pages/terms-conditions']">terms and conditions</a></li>
                                    <li><a [routerLink]="['/pages/privacy-policy']">Privacy and policy</a></li>
                                    <li><a href="#latest-item">Latest Items</a></li>
                                    <li><a href="#feature-arts">featured product</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer black-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p>
                            <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Artistan LLC. All rights reserved. 
                            <!-- Artistan Technology by: <a target="_blank" href="https://www.itolo.net"><img class="img-20 footer-it-logo" src="assets/images/logo-it/logo-it-2.png"></a> -->
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-faq #faqView ></app-faq>
<!-- footer end -->