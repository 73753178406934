<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12 m-a">
                    <div class="quick-view-img">
                        <owl-carousel-o [options]="ProductDetailsMainSliderConfig" class="product-slick1">
                            <ng-container *ngFor="let image of product?.Images">
                                <ng-template carouselSlide>
                                    <div>
                                        <pinch-zoom>
                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                [lazyLoad]="url+image.src" [alt]="image.alt" class="img-fluid">
                                        </pinch-zoom>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product.title | titlecase }}</h2>
                        <h3>{{ product.price * currency?.price | discount:product | currency:currency?.currency:'symbol'
                            }}
                            <del *ngIf="product?.discount"><span class="money">{{ product.price * currency?.price |
                                    currency:currency?.currency:'symbol' }}</span></del>
                        </h3>
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p>{{ product.description.substring(0, 200)+'...' }}</p>
                        </div>
                        <div class="product-description border-product">
                            <!-- <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="avalibility"><span>Schools</span></h5>
                                    <ul class="category-link">
                                        <li *ngFor="let cat of product?.ItemCategories" class="d-block"><a
                                                class="color-inherit" [routerLink]="['/shop/collection/left/sidebar']"
                                                [queryParams]="{ category: cat?.Category?.cat_name}">{{cat?.Category?.cat_name}}</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <h5 class="avalibility"><span>Media</span></h5>
                                    <ul class="category-link">
                                        <li> <span *ngFor="let mat of product?.ItemMaterials; let i = index"
                                                [attr.data-index]="i"> <a class="color-inherit"
                                                    [routerLink]="['/shop/collection/left/sidebar']"
                                                    [queryParams]="{ material: mat?.Material?.mat_name}">{{mat?.Material?.mat_name}}</a>{{product?.ItemMaterials.length==(i+1)?'':',
                                                '}}</span></li>
                                    </ul>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <h5 class="avalibility"><span>Dimension</span></h5>
                                    <ul class="category-link">
                                        <li class="d-block">Width: {{product?.dx}} inch</li>
                                        <li class="d-block">Height: {{product?.dy}} inch</li>
                                    </ul>
                                    <!-- <div class="d-flex">
                                        <h5 class="d-flex "><span class="dx">( {{product?.dx}} </span> <span class="x"> x </span> <span class="dy"> {{product?.dy}} ) </span> <span> Inch</span></h5>
                                    </div> -->
                                </div>
                            </div>

                            <!-- <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5> -->
                            <!-- <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group"> -->
                            <!-- <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span> -->
                            <!-- <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled> -->
                            <!-- <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()" >
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span> -->
                            <!-- </div>
                            </div> -->
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid"
                                [class.disabled]="counter > product.stock" (click)="addToCart(product)"
                                *ngIf="!product?.Arders?.length && userId!=product?.userId">add to cart</a>
                            <!-- [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" -->
                            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"
                                class="btn btn-solid {{!product?.Arders?.length?'':'ml-0'}}">view detail</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>