<ng-template class="theme-modal" #shareView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="container mt-4">
                <div class="row">
                    <div class="col-sm-12">
                        <div>
                            <share-buttons theme="circles-dark"
                                [include]="['copy','facebook', 'email', 'linkedin',  'twitter', 'whatsapp','pinterest',]"
                                [showIcon]="true" [showText]="true" url="{{sharedLink+product?.title?.replace(' ', '-')}}"
                                description="{{ product.description.substring(0, 200)+'...' }}" twitterAccount="rodrigokamada" class="align-center">
                            </share-buttons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>