<div class="media">
    <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
             [lazyLoad]="ImageSrc ? ImageSrc : url+product.Images[0].src"  
             class="img-fluid lazy-loading" alt="{{ product.Images[0].alt }}">
    </a>
	<div class="media-body align-self-center">
		<div class="rating">
			<bar-rating [rate]="5" [readOnly]="true"></bar-rating>
		</div>
        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
            <h6>{{ product.title | titlecase }}</h6>
        </a>
		<h4>{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h4>
        <!-- <ul class="color-variant">
            <li [class]="color" 
                *ngFor="let color of Color(product?.variants)" 
                [ngStyle]="{'background-color': color}"
                (click)="ChangeVariants(color, product)">
            </li>
        </ul> -->
	</div>
</div>