import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ShareModalComponent } from '../../modal/share-modal/share-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-box-latest',
  templateUrl: './product-box-latest.component.html',
  styleUrls: ['./product-box-latest.component.scss']
})
export class ProductBoxLatestComponent implements OnInit {
  url = environment.imgURL+'items/';
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @ViewChild("shareView") ShareView: ShareModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    // console.log('item Images  ', this.product.Images);
  }

  // Get Product Color
  // Color(variants) {
  //   const uniqColor = [];
  //   for (let i = 0; i < Object.keys(variants).length; i++) {
  //     if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
  //       uniqColor.push(variants[i].color)
  //     }
  //   }
  //   return uniqColor
  // }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  removeFromWishlist(product: any) {
    this.productService.removeWishlistItem(product);
  }

  isInWishlist(id){
    return this.productService.isInWishlist(id.toString());
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
