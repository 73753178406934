<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="product.new">new</span> -->
      <!-- <span class="lable4" *ngIf="product.sale">on sale</span> -->
    </div>
    <div class="front">
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"> -->
        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : url+product.Images[0].src"
          class="img-fluid lazy-loading blured-img"
          alt="{{ product.Images[0].alt }}" />
        <img
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : url+product.Images[0].src"
          class="img-fluid lazy-loading main-img"
          alt="{{ product.Images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.Images[1].src" class="img-fluid lazy-loading" alt="{{ product.Images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.Images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="(cartModal && !product?.Arders?.length) && userId!=product?.userId">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal && !product?.Arders?.length && userId!=product?.userId" >
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="isInWishlist(product.id) ? removeFromWishlist(product) : addToWishlist(product)" *ngIf="!product?.Arders?.length">
        <i [class]="isInWishlist(product.id) ? 'text-danger fa fa-heart' : 'ti-heart'" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Share To" (click)="ShareView.openModal()" *ngIf="!product?.Arders?.length">
        <i class="ti-share" aria-hidden="true"></i>
      </a>
      <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a> -->
    </div>
  </div>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
        <a [routerLink]="['/pages/vendor/vendor-profile/', product?.User?.slug]" class="mt-2 mb-3 d-block">
          <h6 class="mt-2 mb-3 them-color">{{ product?.User?.brandName }}</h6>
        </a>
      
      <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]" class="mt-3 mb-3">
        <h6 lass="mt-2 mb0">{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <div class="justify-content-between d-flex">
        <h4>
          {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
          <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
        </h4>
        <!-- <div class="view_qty-container" *ngIf="product?.view>0">
          <i class="ti-eye" aria-hidden="true" ></i>
          <span class="view_qty_cls">{{ formatProductView(product?.view) }}</span>
        </div> -->
      </div>
      
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-share-modal #shareView [product]="product" ></app-share-modal>
<app-quick-view #quickView [product]="product" [userId]="userId" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

