import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private TRANSACTION = environment.apiURL + '/transaction';
  baseUrl = environment.apiURL+'/fronts';
  pageBaseUrl = environment.apiURL+'/page';
  SocialBaseUrl = environment.apiURL + '/contact/social';
  ContactFormBaseUrl = environment.apiURL + '/contact/form';
  ContactInfoBaseUrl = environment.apiURL + '/contact/info';
  
  url = environment.apiURL;
  constructor(private http: HttpClient) { }

  allSocials(): Observable<any> {
    return this.http.get(`${this.SocialBaseUrl}`);
  }

  createContactForm(data: {}): Observable<any> {
    return this.http.post(this.ContactFormBaseUrl, data);
  }

  getPage(name:string): Observable<any> {
    return this.http.get(`${this.pageBaseUrl}/${name}?name=${name}`);
  }
  
  allContactInfo(): Observable<any> {
    return this.http.get(`${this.ContactInfoBaseUrl}`);
  }
  
  getAttribute(): Observable<any> {
    return this.http.get(`${this.baseUrl}/attribute/`);
  }

  getFeaturedItems(): Observable<any> {
    return this.http.get(`${this.baseUrl}/featured/`);
  }
  getEvents(): Observable<any> {
    return this.http.get(`${this.url}/event/`);
  }
  getItems(): Observable<any> {
    return this.http.get(`${this.baseUrl}/items/`);
  }
  getTax(data: {}): Observable<any> {
    return this.http.post(`${this.baseUrl}/tax/`, data);
  }
  getFaq(): Observable<any> {
    return this.http.get(`${this.url}/faq/`);
  }

  getOrderById(id:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/customer/arders/${id}`);
  }

  getOrderedItemById(id:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/vendor/arders/${id}`);
  }

  getTransactionByUserId(id:number): Observable<any> {
    return this.http.get(`${this.TRANSACTION}/${id}`);
  }

  getTransactionByVendorId(id:number): Observable<any> {
    return this.http.get(`${this.TRANSACTION}/vendor/${id}`);
  }
  // getItems(): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/${id}`);
  // }

  // create(data: {}): Observable<any> {
  //   return this.http.post(this.baseUrl, data);
  // }

  getItemAttribute(): Observable<any> {
    return this.http.get(`${this.url}/items/attribute/`);
  }
  getItemMinMax(): Observable<any> {
    return this.http.get(`${this.url}/items/minandmax/`);
  }

  updateItem(id: Number, data: {}): Observable<any> {
    return this.http.put(`${this.url}/items/${id}`, data);
  }

  deleteItem(id: Number, by): Observable<any> {
    return this.http.delete(`${this.url}/items/${id}?by=${by}`);
  }
  deleteImageItem(id: Number): Observable<any> {
    return this.http.delete(`${this.url}/items/image/${id}`);
  }
}
