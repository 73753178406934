import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { SearchViewComponent } from "../modal/search-modal/search-view.component";
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  url = environment.imgURL+'items/';
  @ViewChild("searchView") SearchView: SearchViewComponent;
  public products: Product[] = [];
  public search: boolean = false;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService, public authService: AuthService, public tokenStorage: TokenStorageService) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }
  loggedIn: boolean
  user: any
  link: any
  ngOnInit(): void {
    this.loginLable();
    // console.log('on init function call');
  }
  loginLable(){
    this.user= this.tokenStorage.getUser();
    this.loggedIn = this.authService.loggedIn();
    // console.log('user lname', this.user.lname);
    if(!this.loggedIn){
      this.link=['/pages/login']
    }else{
      this.link=['/pages/dashboard']
      // window.location.reload();
    }
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
