import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FaqComponent } from '../../components/modal/faq/faq.component'; 
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsletterService } from '../../services/newsletter.service';
import { ToastrService } from 'ngx-toastr';
import { MainService } from '../../services/main.service';
import { Social } from '../../tables/interface/social';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactInfo } from '../../tables/interface/contact-info';
@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {
  public emailForm:  FormGroup = this.fb.group({
    email: ['', Validators.required],
    userId: ['']
  });
  submitted = false;

  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  
  public socials: Social[] = [];
  public contactInfo: ContactInfo;

  public today: number = Date.now();
  @ViewChild("faqView") FaqView: FaqComponent;
  constructor(private newsletterService: NewsletterService, private fb: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService, private mainService: MainService) { 
    this.getSocials();
    this.getContactInfo();
  }

  ngOnInit(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.emailForm.controls;
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }

    this.newsletterService.create(this.emailForm.value).subscribe(
      response => {
        // console.log(response);
        this.toastr.success(response.title, response.message);
        // window.location.reload();
      },
      error => {
        // console.log(error);
        this.toastr.error(error.title, error.message);
      }
    );
    this.emailForm.reset(); 
  }

  getSocials() {
    this.spinner.show();
    this.mainService.allSocials().subscribe(
      (res) => {
        this.socials = [];
        res.forEach((row, index) => {
          this.socials.push({
            id: row.id,
            name: row.name,
            link: row.link,
            icon: row.icon,
          });
        });
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error(err.message, 'Error!');
      }
    );
    // console.log('ven_list list: ', this.ven_list);
  }

  getContactInfo() {
    this.spinner.show();
    this.mainService.allContactInfo().subscribe(
      (res) => {
        
        res.forEach((row, index) => { 

          this.contactInfo={
           id:row.id,
           phone1:row.phone1,
           phone2:row.phone2,
           email1:row.email1,
           email2:row.email2,
           fax1:row.fax1,
           fax2:row.fax2,
           address:row.address,
           map:row.map,
          }
          
        });
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.toastr.error(err.message, 'Error!');
      }
    );
    // console.log('ven_list list: ', this.ven_list);
  }
}
