<ng-template class="theme-modal" #searchView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="container">
                <section class="search-block">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 offset-lg-3">
                        <form class="form-header" (ngSubmit)="onSearch()">
                          <div class="form-group">
                            <input type="text" class="form-control" id="search" name="search" [formControl]="checkoutForm.controls['search']" [value]="checkoutForm.value['search']" placeholder="Search a Product or Artist">
                </div>
                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
                <div class="row">
                    <div class="col-xl-12 category-search-sect p-0">
                        <!-- collection category -->
                        <section class="p-0">
                        <div class="full-banner parallax-banner1 parallax text-center p-left pt-0">
                            <div class="container-fluid">
                            <div class="row partition3">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12 m-b-3" *ngFor="let cat of categories">
                                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ school: cat.cat_name}">
                                    <div class="collection-banner p-right text-right">
                                    <img src="{{url}}{{cat?.cat_image}}" alt="{{cat.cat_name}}" class="img-fluid lazyload" id='category-img'>
                                    <div class="contain-banner banner-3">
                                        <div>
                                        <h4>+{{cat.ItemCategories?.length}} Items</h4>
                                        <h2>{{cat.cat_name}}</h2>
                                        </div>
                                    </div>
                                    </div>
                                </a>
                                </div>
                            </div>
                            <section class="p-0">
                                <div class="row partition1 p-2">
                                    <div class="p-2 mb-2" *ngFor="let mat of materials">
                                        <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ medium: mat.mat_name}" class="btn btn-outline btn-block">
                                            {{mat.mat_name}}
                                        </a>
                                    </div>
                                </div>
                            </section>
                            </div>

                        </div>
                        </section>
                        <!-- collection Category end -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>