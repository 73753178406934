import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';


@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {
  public emailForm:  FormGroup = this.fb.group({
    email: ['', Validators.required],
    userId: ['']
  });
  submitted = false;


  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(private newsletterService: NewsletterService, private fb: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.emailForm.controls;
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }

    this.newsletterService.create(this.emailForm.value).subscribe(
      response => {
        // console.log(response);
        this.toastr.success(response.title, response.message);
        // window.location.reload();
      },
      error => {
        // console.log(error);
        this.toastr.error(error.title, error.message);
      }
    );
    this.emailForm.reset();
    this.modalService.dismissAll();
  }

  ngAfterViewInit(): void {
    if(localStorage.getItem("newsletter") !== 'true')
       this.openModal();
    localStorage.setItem("newsletter", 'true');
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.NewsLetterModal, {
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
