<ng-template class="theme-modal" #faqView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <!-- section start-->
            <section class="faq-section section-b-space">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <ngx-spinner type="ball-scale-multiple" id="loading"></ngx-spinner>
                            <div *ngIf="faqs.length>0">
                                <ngb-accordion [closeOthers]="true" activeIds="faq-1" class="accordion theme-accordion" id="accordionExample"  *ngFor="let faq of faqs">
                                    <ngb-panel id="faq-{{faq.id}}" title="{{faq.question}}">
                                        <ng-template ngbPanelContent>
                                            <p>{{faq.answer}}</p>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--Section ends-->
        </div>
    </div>
</ng-template>