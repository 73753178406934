<!-- Edit modal popup start-->
<ng-template #editItem let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Update Item</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <form class="needs-validation" [formGroup]="up_item" (ngSubmit)="onSubmitUpdate()"> -->
        <div class="modal-body">
            <!-- <div class="form">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <input class="form-control" name="id" id="id" type="hidden" [formControl]="up_item.controls['id']">
                            <label for="title" class="mb-1"><span>*</span> Title</label>
                            <input class="form-control" name="title" id="title" type="text" [ngClass]="{'is-invalid': submitted && uf.title.errors}"
                                [formControl]="up_item.controls['title']">
                            <div *ngIf="(uf.title.touched || uf.title.dirty) && uf.title.errors" class="text text-danger">
                                Title is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <label for="slug" class="mb-1"><span>*</span> Slug</label>
                            <input class="form-control" name="slug" id="slug" type="text" [ngClass]="{'is-invalid': submitted && uf.slug.errors}"
                                [formControl]="up_item.controls['slug']">
                            <div *ngIf="(uf.slug.touched || uf.slug.dirty) && uf.slug.errors" class="text text-danger">
                                Slug is required.
                            </div>
                        </div>
                    </div>
                
                    <div class="col-xl-2 col-lg-2 col-md-6">
                        <div class="form-group">
                            <label for="price" class="mb-1"><span>*</span> Price</label>
                            <i class='dollar-icon'>$</i>
                            <input class="form-control" name="price" id="price" type="number" [ngClass]="{'is-invalid': submitted && uf.price.errors}"
                                [formControl]="up_item.controls['price']">
                            <div *ngIf="(uf.price.touched || uf.price.dirty) && uf.price.errors" class="text text-danger">
                                Item Price is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-6">
                        <div class="form-group">
                            <label for="discount" class="mb-1"><span>*</span> Item discount</label>
                            <i class='dollar-icon'>$</i>
                            <input class="form-control" name="discount" id="discount" type="number" [ngClass]="{'is-invalid': submitted && uf.discount.errors}"
                                [formControl]="up_item.controls['discount']">
                        </div>
                    </div>
                
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group"> 
                            <label for="exampleFormControlSelectCategory" class="col-form-label pt-0">Select Category</label>
                            <ng-multiselect-dropdown
                            class= "dropdownlist p-0"
                            [placeholder]="'Select Category'"
                            [settings]="categorySettings"
                            [data]="categories"
                            [formControl]="up_item.controls['selectedCat']" required
                            (onSelect)="onCatSelect($event)"
                            (onSelectAll)="onSelectAllCat($event)"
                            >
                            </ng-multiselect-dropdown>
                            <div *ngIf="up_item.controls.selectedCat.touched && up_item.controls.selectedCat.errors?.required"
                                class="text text-danger">
                                Category is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group"> 
                            <label for="exampleFormControlSelect1" class="col-form-label pt-0">Select Materials</label>
                            <ng-multiselect-dropdown
                            class= "dropdownlist p-0"
                            [placeholder]="'Select Materials'"
                            [settings]="dropdownSettings"
                            [data]="materialList"
                            [formControl]="up_item.controls['material']" required=''
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            >
                            </ng-multiselect-dropdown>
                            <div *ngIf="up_item.controls.material.touched && up_item.controls.material.errors?.required"
                                class="text text-danger">
                                Material is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <label class="col-form-label pt-0"><span>*</span> Total Item</label>
                            <div class="qty-box1">
                                <div class="input-group">
                                    <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                    <input type="number" class="touchspin1 text-center"  name="stock" id="stock" [formControl]="up_item.controls['stock']" >
                                    <i class="fa fa-plus btnLess1" (click)=increment()></i>
                                </div>
                            </div>
                            <div *ngIf="up_item.controls['stock'].touched && up_item.controls['stock'].errors?.required"
                                class="text text-danger">
                                Total Item is required.
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <label for="status" class="mb-1"><span>*</span> Status :</label>
                            <select name="status" id="status" class="form-control" [ngClass]="{'is-invalid': submitted && uf.status.errors}"
                                [formControl]="up_item.controls['status']" >
                                <option value="" >Choice One</option>
                                <option value="Pending" [selected]="up_item.controls['status'].value=='Pending'" >Pending</option>
                                <option value="Active" [selected]="up_item.controls['status'].value=='Active'" >Active</option>
                                <option value="Inactive" [selected]="up_item.controls['status'].value=='Inactive'" >Inactive</option>
                            </select>
                            <div *ngIf="(uf.status.touched || uf.status.dirty) && uf.status.errors" class="text text-danger">
                                Status is required.
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <label for="metaTitle" class="col-form-label pt-0"> Meta Title</label>
                            <input class="form-control" id="metaTitle" name="metaTitle" type="text" [formControl]="up_item.controls['metaTitle']">
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="form-group">
                            <label class="col-form-label pt-0">Dimension</label>
                            <div class="qty-box1 dimension-box">
                                <div class="input-group">
                                    <input class="touchspin1 text-center" [formControl]="up_item.controls['dx']" name="dx" type="number">
                                    <i class="fa btnLess1 btnx">x</i>
                                    <input class="touchspin1 text-center" [formControl]="up_item.controls['dy']" name="dy" type="number">
                                    <i class="fa btnLess1">Inch</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label pt-0">Current Images</label>
                             <div class="custom-dropzone dropzone-custom">
                                <div class="ngx-dropzone-preview" *ngFor="let f of up_item.controls['old_images'].value">
                                    <img class="ngx-dropzone-preview-img" src="{{url}}/{{f.src}}">
                                    <a href="javascript:void(0)" class="delete-custome"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </div>
                             </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label pt-0">New Images</label>
                            <div class="custom-dropzone dropzone-custom" ngx-dropzone [multiple]='true' [accept]="'image/*'" (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="form-group">
                            <label for="metaDescription" class="col-form-label">Meta Description</label>
                            <textarea class="form-control" rows="7" cols="12" id="metaDescription" name="metaDescription" [formControl]="up_item.controls['metaDescription']" ></textarea>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="form-group">
                            <label for="description" class="col-form-label">Description</label>
                            <textarea class="form-control" id="description" name="description" rows="7" cols="12" [formControl]="up_item.controls['description']"></textarea>
                            <div *ngIf="up_item.controls['description'].touched && up_item.controls['description'].errors?.required"
                                class="text text-danger">
                                Description is required.
                            </div>
                        </div>
                    </div>

                     
                </div>
            </div> -->

        </div>
        <div class="modal-footer">
            <!-- <button type="submit" class="btn btn-primary" [disabled]="!up_item.valid">Update</button> -->
            <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss( 'Cross click')">Close</button>
        </div>
    <!-- </form> -->
</ng-template>
 
<!-- Edit modal popup end-->